<template>
  <Dialog class="backdrop-blur-sm" extend-class=" md:max-w-lg">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-end">
            <img src="../assets/icons/closeline.svg" class="cursor-pointer" alt="" @click="close" />
          </div>
          <div class="flex justify-center pt-2">
            <img src="@/assets/images/share.svg" class="bg-[#F0386A]/10 rounded-full p-2 mb-7" />
          </div>

          <div class="pt-4">
            <h2 class="font-semibold text-brand-black leading-[160%] text-center text-[22px]">Share Job Posting</h2>
            <p class="my-6 text-[14px] text-light-gray text-center">Share on your social media for more visibility!</p>
          </div>

          <div class="flex justify-center mb-8">
            <ShareNetwork
              class="border rounded-xl p-3 mx-1.5"
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{ borderColor: network.color, color: network.color }"
              :url="sharing?.url"
              :title="sharing?.title"
              :description="sharing?.description"
              :quote="sharing?.quote"
              :hashtags="sharing?.hashtags"
              :twitterUser="sharing?.twitterUser"
            >
              <div class="flex items-center">
                <img :src="network.icon" class="mr-1" />
                <span>{{ network.name }}</span>
              </div>
            </ShareNetwork>
            <div class="border border-[#12080F] rounded-xl p-3 mx-1 cursor-pointer" @click="copy">
              <div class="flex items-center">
                <img src="@/assets/icons/link.svg" class="mr-1" />
                <button type="button" v-clipboard:copy="sharing?.url" @click="textCopy" v-clipboard:error="onError" class="whitespace-nowrap text-[#12080F] text-base">
                  Copy Link
                </button>
              </div>
            </div>
          </div>

          <div class="pt-2">
            <button type="button" @click.prevent="close" class="bg-white p-4 border border-brand-black rounded-lg w-full block">Close</button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import TamModal from './TamModal.vue';
import { createJobLink, role, alert } from '@/utils/helper';
import Dialog from '@/components/Dialog.vue';

const props = defineProps(['jobDetails', 'isActive']);
const emit = defineEmits(['close']);

const sharing = computed(() => {
  return {
    url: `${process.env.VUE_APP_DOMAIN}${createJobLink(props.jobDetails)}`,
    title: `We're hiring for the role of ${role(props.jobDetails)}`,
    // description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
    hashtags: 'recruitment,careerbuddy,hiring',
  };
});

const networks = ref([
  // { network: 'facebook', name: 'Facebook', icon: require('@/assets/icons/fb.svg'), color: '#415E9B' },
  { network: 'linkedin', name: 'LinkedIn', icon: require('@/assets/icons/linkedin-small.svg'), color: '#007bb5' },
  { network: 'twitter', name: 'Twitter', icon: require('@/assets/icons/twi.svg'), color: '#1DA1F2' },
  { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
]);

const textCopy = () => {
  alert('Job link copied successfully');
};

const onError = (e) => {
  alert('Failed to copy texts');
};

const close = () => {
  emit('close');
};
</script>
